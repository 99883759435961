<template>
  <v-timeline-item icon="mdi-creation">
    <v-card class="mr-16">
      <v-toolbar color="primary" dark dense>
        <v-toolbar-title>{{ note.title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title class="subtitle-2">1.1.0</v-toolbar-title>
      </v-toolbar>
      <v-card-subtitle>{{ date(note.date) }}</v-card-subtitle>
      <v-card-text>
        <v-card-text class="px-12 py-4">
          <p class="text-justify">{{ note.text }}</p>
        </v-card-text>
        <v-card-text class="note-item-container d-flex flex-row flex-nowrap align-start">
          <div>
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Fonctionnalités</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list three-line>
                <v-list-item v-for="(item, i) in note.notes" :key="i">
                  <v-list-item-icon>
                    <planete-icon>planete-online</planete-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <div class="grey--text text--darken-1 mt-2 text-justify">{{ item.text }}</div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-list-group>
          </div>
          <div>
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Corrections</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list dense>
                <v-list-item v-for="(item, i) in note.bugs" :key="i" dense>
                  <v-list-item-icon>
                    <v-icon>mdi-bug</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{ item.text }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-list-group>
          </div>
        </v-card-text>
      </v-card-text>
    </v-card>
  </v-timeline-item>
</template>

<script>
import moment from "moment";

export default {
  name: "UpdateNote",
  props: {
    note: Object
  },
  methods: {
    date(date) {
      return moment(date).locale(this.$i18n.locale).format("dddd LL")
    }
  },
}
</script>

<style scoped>
.note-item-container > div {
  width: 50%;
}
</style>